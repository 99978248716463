import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typist from "react-typist";
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Style from "./style.js";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
      opacity: 0.8,
    },
  },
}))(Button);

const Layout = ({
  dataGenders,
  dataSeason,
  onChangeSeason,
  modalFilterShop,
  filterShop,
  openModal,
  closeModal,
  onGo,
  dataShowBanner,
  dataSaleProducts,
  dataBestsellerProducts,
  dataLuxuryProducts,
  history,
  dataMe
}) => {
  const womanId = dataGenders.genders.find(item => item.slug === "donna") || false;
  const manId = dataGenders.genders.find(item => item.slug === "uomo") || false;
  const style = Style();

  return (
    <>
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Typography variant="body1" align="center" fullWidth color="primary" style={{paddingLeft: "0.15rem"}}><b>Visualizza i cataloghi A.I. 22.23</b></Typography>
          </Grid>
          <Grid className={style.wrapperButtonAddNewLink} item xs={12}>
            <a className={style.buttonAddNewLink} href="/catalogo-luxury" target="_blank" >Luxury</a>
            <a className={style.buttonAddNewLink} href="/catalogo-uomo" target="_blank" >Uomo</a>
            <a className={style.buttonAddNewLink} href="/catalogo-donna" target="_blank" >Donna</a>
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper elevation={3} className={style.paperShop} onClick={() => openModal("no-gender")}>
              <div className={style.paperGrid}>
                <Typist cursor={{ element: false }}>
                  <span className={style.typewriterText}>CASHMERE COMPANY</span>
                  <br />
                  <p className={style.typewriterSecondText} style={{
                    backgroundColor: "#472920",
                    padding: "1rem",
                    borderRadius: "8px",
                  }} onClick={() => openModal("no-gender")}
                  >Consulta lo Shop</p>
                </Typist>
              </div>
            </Paper>
          </Grid> */}
          {/* PREORDER */}
          {(dataSeason.seasons ?? []).filter(season => season.is_preorder === 1 && season.not_visible !== 1).length > 0 && dataMe.me.agent?.id && dataMe.me.agent?.preorder &&
            <Grid item xs={12}>
              <Paper elevation={3} className={style.paperPreorder}>
                <Typography variant="h3">PREORDER</Typography>
                <ColorButton
                  style={{ backgroundColor: "#472920", color: "#ffffff", maxWidth: "320px" }}
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() => history.push("/preorder")}
                >vai</ColorButton>
              </Paper>
            </Grid>
          }

          <Grid item xs={12} sm={12} md={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6} lg={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6} xl={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6}>
            <Paper elevation={3} className={style.paperSvgMan} onClick={manId ? () => openModal(manId.id) : () => openModal("no-gender")}>
              <div className={style.paperGrid}>
                <div>
                  <h1 className={style.titleCategory}>UOMO</h1>
                  <ColorButton
                    style={{ backgroundColor: "#472920", color: "#ffffff" }}
                    variant="contained"
                    size="small"
                    onClick={manId ? () => openModal(manId.id) : () => openModal("no-gender")}
                  >vai</ColorButton>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6} lg={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6} xl={dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 ? 4 : 6}>
            <Paper elevation={3} className={style.paperSvgWoman} onClick={womanId ? () => openModal(womanId.id) : () => openModal("no-gender")}>
              <div className={style.paperGrid}>
                <div>
                  <h1 className={style.titleCategory}>DONNA</h1>
                  <ColorButton
                    style={{ backgroundColor: "#472920", color: "#ffffff" }}
                    variant="contained"
                    size="small"
                    onClick={womanId ? () => openModal(womanId.id) : () => openModal("no-gender")}
                  >vai</ColorButton>
                </div>
              </div>
            </Paper>
          </Grid>
          {
            dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 1 && dataLuxuryProducts?.getLuxuryProducts.length > 0 && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={3} className={style.paperSvgLuxury}>
                  <div className={style.paperGrid}>
                    <div>
                      <h1 className={style.titleCategory}>NOOS / Mai fuori stock</h1>
                      <ColorButton
                        style={{ backgroundColor: "#472920", color: "#ffffff" }}
                        variant="contained"
                        size="small"
                        onClick={() => history.push("/luxury")}
                      >vai</ColorButton>
                    </div>
                  </div>
                </Paper>
              </Grid>
            )
          }
          {
            dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'sale')].show === 1 && dataSaleProducts?.getSaleProducts.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper elevation={3} className={style.paperSvgSale}>
                  <div className={style.paperGrid}>
                    <div>
                      <h1 className={style.titleCategoryOffer}>ARTICOLI<br />IN SALDO</h1>
                      <ColorButton
                        style={{ backgroundColor: "red", color: "#ffffff" }}
                        variant="contained"
                        size="small"
                        onClick={() => history.push("/sale")}
                      >vai</ColorButton>
                    </div>
                  </div>
                </Paper>
              </Grid>
            )
          }
          {
            dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'bestseller')].show === 1 && dataBestsellerProducts.getBestsellerProducts.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper elevation={3} className={style.paperSvgBest}>
                  <div className={style.paperGrid}>
                    <div>
                      <h1 className={style.titleCategory}>MADE<br />IN ITALY</h1>
                      <ColorButton
                        style={{ backgroundColor: "#472920", color: "#ffffff" }}
                        variant="contained"
                        size="small"
                        onClick={() => history.push("/made-in-italy")}
                      >vai</ColorButton>
                    </div>
                  </div>
                </Paper>
              </Grid>
            )
          }
        </Grid>
      </div>
      <Modal
        open={modalFilterShop}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={style.modal}
      >
        <div className={style.modalBody}>
          <div className={style.modalTitle}>
            <Typography component="h1" variant="h5" align="center">Seleziona una stagione</Typography>
            <span className={style.modalClose} onClick={closeModal}>x</span>
          </div>
          <div className={style.modalContent}>
            <FormControl variant="outlined" size="small" className={style.select}>
              <InputLabel id="demo-simple-select-outlined-label">Stagione</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filterShop.season}
                onChange={onChangeSeason}
                label="Stagione"
                disabled={false}
                fullWidth
              >
                <MenuItem value="all">Tutte le stagioni</MenuItem>
                {
                  dataSeason && dataSeason.seasons !== null && dataSeason.seasons.length > 0 ? (
                    dataSeason.seasons.filter(item => !item.is_preorder).map((item, index) => {
                      if (!item.not_visible) {
                        return (<MenuItem key={index + "sizes"} value={item.id}>{item.name}</MenuItem>)
                      }
                    })
                  ) : (
                    <MenuItem value="">non ci sono stagioni</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </div>
          <div className={style.modalFooter}>
            <ColorButton
              style={{ backgroundColor: "#472920", color: "#ffffff" }}
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              onClick={onGo}
            >
              Vai
            </ColorButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Layout;
